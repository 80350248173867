import { FlatButton } from 'FlatButton';
import { memo } from 'react';

const MessageAndButtonModalComponent = ({
    message,
    buttonText,
    onClick,
}: {
    message: string;
    buttonText: string;
    onClick: () => void;
}) => (
    <div className="flex flex-col items-center justify-center space-y-5">
        <div>{message}</div>
        <FlatButton className="bg-green text-center" onClick={() => onClick()}>
            {buttonText}
        </FlatButton>
    </div>
);

export const MessageAndButtonModal = memo(MessageAndButtonModalComponent) as typeof MessageAndButtonModalComponent;
