import Dexie, { type Table, type Transaction, type TransactionMode } from 'dexie';
import getDexieConfig from './getDexieConfig';
import {
    type ProjectProgressRecord,
    type BookmarkedStream,
    type ConfigRecord,
    type CurrentUserRecord,
    type Event,
    type Image,
    type LessonProgressRecord,
    type ProgressFetch,
    type PublishedLessonContent,
    type PublishedPlaylist,
    type PublishedStream,
    type StreamProgressRecord,
    type StudentDashboardContentFetch,
} from './FrontRoyalStore.types';

// For debugging dexie issues
// Dexie.debug = true;

// eslint-disable-next-line quantic/no-type-exports
export type FrontRoyalStoreDBTableNames = {
    [TableName in keyof FrontRoyalStoreDB]: FrontRoyalStoreDB[TableName] extends Dexie.Table ? TableName : never;
}[keyof FrontRoyalStoreDB];

export default class FrontRoyalStoreDB extends Dexie {
    dbTechnology: string;
    _originalTransaction: typeof Dexie.prototype.transaction;

    // Declare implicit table properties.
    // (just to inform Typescript. Instantiated by Dexie in stores() method)
    bookmarkedStreams!: Dexie.Table<BookmarkedStream, string>; // string = type of the primkey
    configRecords!: Dexie.Table<ConfigRecord, string>;
    currentUsers!: Dexie.Table<CurrentUserRecord, string>;
    events!: Dexie.Table<Event, string>;
    images!: Dexie.Table<Image, string>;
    lessonProgress!: Dexie.Table<LessonProgressRecord, string>;
    progressFetches!: Dexie.Table<ProgressFetch, string>;
    publishedLessonContent!: Dexie.Table<PublishedLessonContent, string>;
    publishedPlaylists!: Dexie.Table<PublishedPlaylist, string>;
    publishedStreams!: Dexie.Table<PublishedStream, string>;
    streamProgress!: Dexie.Table<StreamProgressRecord, string>;
    projectProgress!: Dexie.Table<ProjectProgressRecord, string>;
    studentDashboardContentFetches!: Dexie.Table<StudentDashboardContentFetch, string>;
    validDbPings!: Dexie.Table<{ id: string }, string>;

    constructor(dbTechnology: string) {
        const dexieConfig = getDexieConfig(dbTechnology);
        super('FrontRoyal', dexieConfig);

        this.dbTechnology = dbTechnology;

        // For information on setting up Dexie tables and indexes, see
        // see https://dexie.org/docs/Version/Version.stores()#schema-syntax
        // and https://dexie.org/docs/Tutorial/Design#database-versioning
        //
        // Versions here are like migrations in rails.  Each new version specifies
        // changes to the database.
        this.version(1).stores({
            streams: '&id',
            images: '&url',
            lessonProgress: '&[user_id+locale_pack_id],synced_to_server,&fr_version',
            streamProgress: '&[user_id+locale_pack_id],synced_to_server,&fr_version',
            events: '&id,client_utc_timestamp',
        });

        this.version(2).stores({
            // See ensureUserProgressFetched.  If we have ever fetched progress for this
            // user from the database, we record a record in progressFetches so we
            // know we can use the local store and do not need to hit the server again.
            progressFetches: '&user_id',
            bookmarkedStreams: '&[user_id+locale_pack_id]',

            // adding user_id+updated_at indexes
            lessonProgress: '&[user_id+locale_pack_id],synced_to_server,&fr_version,[user_id+updated_at]',
            streamProgress: '&[user_id+locale_pack_id],synced_to_server,&fr_version,[user_id+updated_at]',
        });

        this.version(3).stores({
            streams: null,
            publishedStreams: '&id,all_content_stored',

            // publishedLessonContent holds the full frame content for each lesson.
            // It is keyed off of the stream_id and the lesson_id so that we can reliably
            // delete a record from publishedLessonContent when we want to make a stream no
            // longer available offline.  Since, theoretically, lessons can be shared between
            // streams, this would not be safe otherwise.
            publishedLessonContent: '&[stream_id+id], *image_urls',
        });

        this.version(4).stores({
            configRecords: '&id',
            currentUsers: '&id',
        });

        // Since we did not previous put serverClientTimeOffset in configRecords,
        // we have to remove any existing records
        this.version(5).upgrade(tx => tx.table('configRecords').clear());

        this.version(6).stores({
            sentryErrors: '&id',
        });

        this.version(7).stores({
            publishedPlaylists: '&id,locale_pack_id',
        });

        this.version(8).stores({
            // Similar to progressFetches, if we ever fetched the student dashboard for this user,
            // we add a record to the store so we know we can use the local store and do not need
            // to hit the server again.
            studentDashboardFetches: '&user_id',
            currentUsers: '&id,synced_to_server', // adds synced_to_server
        });

        this.version(9).stores({
            publishedStreams: '&id,all_content_stored,locale_pack.id', // adds locale_pack_id
        });

        this.version(10)
            .stores({
                studentDashboardFetches: null,
                studentDashboardContentFetches:
                    '&[user_id+cohort_version_id+pref_locale+content_views_refresh_updated_at]',
            })
            .upgrade(tx =>
                // Since existing records do not have content_views_refresh_updated_at in them, we cannot use them
                tx.table('configRecords').clear(),
            );

        this.version(11).stores({
            validDbPings: '&id', // This is just used for us to test if the database really works (see FrontRoyalStore##initializeDb)
        });

        this.version(12).stores({
            // Drop table to change primary key in 13, otherwise Dexie.UpgradeError
            studentDashboardContentFetches: null,
        });

        this.version(13).stores({
            // Adds cohort_id
            studentDashboardContentFetches:
                '&[user_id+cohort_id+cohort_version_id+pref_locale+content_views_refresh_updated_at]',
        });

        this.version(14).stores({
            sentryErrors: null,
        });

        this.version(15).stores({
            projectProgress: '&[user_id+requirement_identifier],[user_id+updated_at]',
        });

        // Since existing records do not have userProgramFieldStates in them, we cannot use them
        this.version(16).upgrade(tx => tx.table('currentUsers').clear());

        this.version(17).upgrade(tx => {
            // Since we never accounted for projects done before the adding projectProgress
            // we need to clear all progress so that max_updated_at becomes 0 and we can refetch all progress
            tx.table('projectProgress').clear();
            tx.table('lessonProgress').clear();
            tx.table('streamProgress').clear();
            tx.table('progressFetches').clear();
        });

        this._originalTransaction = this.transaction;

        this.transaction = function () {
            throw new Error(
                'Do not use FrontRoyalStoreDB.transaction. Use safeTransaction instead, and make sure to read the comments about it.',
            );
        };
    }

    // The point of the safeTransaction method is to make sure that developers read and understand
    // https://github.com/indexeddbshim/IndexedDBShim/issues/361#issuecomment-2517874893 before using db.transaction.
    // In summary, db.transaction might not work as expected in iOS because we're using IndexedDBShim with SqlLite there. So,
    // 1. In the callback function, do not use any async functions
    // 2. Make sure that you've tested your code in iOS (emulator should be fine) before merging it.
    safeTransaction<U>(
        // FIXME: I'd rather support all of the different method signatures that Dexie.prototype.transaction supports, but
        // typescript was complaining and it was urgent to get this out.
        mode: TransactionMode,
        tables: Table[],
        scope: (trans: Transaction) => U | PromiseLike<U>,
    ): Promise<U> {
        return this._originalTransaction(mode, tables, scope);
    }
}
