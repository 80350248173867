import { memo } from 'react';
import { ModalBackdrop } from './ModalBackdrop';

function FrontRoyalModalComponent({ title, children }: { title?: string; children: React.ReactNode }) {
    return (
        <ModalBackdrop>
            {/* Modal Box */}
            <div className="mx-auto flex w-full max-w-sm flex-col items-center justify-center rounded-lg bg-white p-6 shadow-[0_5px_15px_rgba(0,0,0,0.5)] shadow-lg">
                {/* Modal Title */}
                {title && <h2 className="mb-4 text-center text-[24px] font-semibold text-black">{title}</h2>}
                {/* Modal Body */}
                <div className="text-center text-beige-beyond-beyond-dark">{children}</div>
            </div>
        </ModalBackdrop>
    );
}

export const FrontRoyalModal = memo(FrontRoyalModalComponent) as typeof FrontRoyalModalComponent;
